<template>
  <div class="contactInfo">
    <!-- PC端 -->
    <img src="../../assets/images/edu/cont-banner.png" class="contact-bg-pc">
    <img src="../../assets/images/edu/cont-mobile-banner.png" class="contact-bg-mobile">
    <!--<div class="contact-bg">
       <div class="text-box">
        <div class="inner-box">
          多样化合作模式 有效匹配合作资源
        </div>
      </div>
    </div>-->
    <div class="outer-box">
      <div class="map-box-pc">
        <div class="map-title">
          <p class="title">
            联系我们
          </p>
          <p class="sub-title">
            contact us
          </p>
        </div>
        <div class="map-detail">
          <div class="line">
            <i class="bg-icon icon-img1 icon" /><span>学校合作热线：400-870-1117</span>
          </div>
          <div class="line line2">
            <i class="bg-icon icon-img2 icon" /><span>座    机：0731-84457795 / 0731-84457796</span>
          </div>
          <div class="line">
            <i class="bg-icon icon-img3 icon" /><span>地    址：湖南省长沙市岳麓区岳麓街道溁左路中南大学科技园（研发）总部5号栋</span>
          </div>
        </div>
        <span class="bg-icon mapImg-pc" />
        <span class="bg-icon mapImg-mobile" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactInfo',
  data() {
    return {};
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .mapImg-mobile{
    display: none;
  }
  .contact-bg-mobile{
    display: none;
  }
  .mapImg-pc{
      width: 100%;
      height: 420px;
      margin-top: 30px;
      display: block;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('../../assets/images/map.png');
  }
  .contactInfo{
    position: relative;
    .contact-bg-pc{
      width: 100%;
      height: 460px;
      object-fit: cover ;
    }
    .outer-box{
      position: relative;
      height: 750px;
    }
    .map-box-pc{
      width: 1200px;
      margin: -100px auto 60px;
      background: #fff;
      padding-bottom: 1200px;
      padding: 60px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 5;
      .map-title{
        text-align: center;
        margin-bottom: 40px;
        .title{
          font-size: 34px;
          font-weight: 400;
          color: #333333;
        }
        .sub-title{
          font-size: 18px;
          font-weight: 400;
          color: #999999;
        }
      }
      .map-detail{
        .line{
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
        }
        .icon{
          width: 24px;
          height: 24px;
          display: block;
          margin-right: 10px;
          &.icon-img1{
            background-image: url('../../assets/images/edu/cont-phone.png');
          }
          &.icon-img2{
            background-image: url('../../assets/images/edu/cont-camera.png');
          }
          &.icon-img3{
            background-image: url('../../assets/images/edu/cont-location.png');
          }
        }
      }
    }
  }
}
@media screen and (min-width:992px) and (max-width: 1200px){
  .mapImg-mobile{
    width: 100% !important;
    height: 500px !important;
  }
  .contact-bg-mobile{
    width: 100% !important;
    height: 450px !important;
  }
}
@media screen and (max-width: 1200px) {
  .mapImg-pc{
    display: none;
  }
  .mapImg-mobile{
      width: 100%;
      height: 228px;
      margin-top: 30px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: block;
      background-image: url('../../assets/images/moblie/mobile-location.png');
  }
  .contactInfo{
    .contact-bg-pc{
      display: none;
    }
    .contact-bg-mobile{
      width: 100%;
      height: 295px;
      // object-fit: cover ;
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // background-image: url('../../assets/images/edu/cont-mobile-banner.png');
    }
    .map-box-pc{
      background: #fff;
      margin: 15px;
      padding: 20px 15px;
      .map-title{
        text-align: center;
        margin-bottom: 40px;
        .title{
          font-size: 23px;
          font-weight: bold;
        }
        .sub-title{
          font-size: 14px;
        }
      }
      .map-detail{
        .line{
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          &.line2{
            margin: 14px 0;
          }
          span{
            width: 90%;
            display: block;
          }
        }
        .icon{
          width: 24px;
          height: 24px;
          display: block;
          margin-right: 10px;
          &.icon-img1{
            background-image: url('../../assets/images/edu/cont-phone.png');
          }
          &.icon-img2{
            background-image: url('../../assets/images/edu/cont-camera.png');
          }
          &.icon-img3{
            background-image: url('../../assets/images/edu/cont-location.png');
          }
        }
      }
    }
  }
}
</style>
